<template>
  <a-form-model
    ref="form"
    :model="formModel"
    :rules="formRules"
    :wrapper-col="{ span: 20 }"
    style="margin-left: 70px"
    @submit="submitClick"
    @submit.native.prevent
  >
    <a-form-model-item style="margin-bottom: 0">
      <span style="font-size: 18px; color: #333333">注册账号</span>
    </a-form-model-item>
    <a-form-model-item prop="mobile">
      <a-input
        v-model="formModel.mobile"
        allowClear
        size="large"
        :maxLength="11"
        placeholder="请输入手机号"
      >
        <a-icon slot="prefix" type="user" />
      </a-input>
    </a-form-model-item>
    <VerificationCode v-model="formModel.code" :validate="validatePhone" />
    <a-form-model-item prop="password">
      <a-input-password
        v-model.trim="formModel.password"
        :maxLength="20"
        placeholder="请设置6~20位密码，不能连号"
        size="large"
        autocomplete="new-password"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input-password>
    </a-form-model-item>
    <a-form-model-item prop="password2">
      <a-input-password
        v-model.trim="formModel.password2"
        :maxLength="20"
        placeholder="请再次输入密码"
        size="large"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input-password>
    </a-form-model-item>
    <a-form-model-item prop="isRead">
      <a-checkbox v-model="formModel.isRead">
        我已阅读并同意
        <router-link to="/docs/user" target="_blank"> 《用户协议》 </router-link
        >和
        <router-link to="/docs/privacy" target="_blank">
          《隐私政策》</router-link
        >
      </a-checkbox>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" block size="large" html-type="submit">
        注册微海账号
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Base64 } from "js-base64";
export default {
  components: {
    VerificationCode: () => import("@/components/system/VerificationCode"),
  },
  data() {
    return {
      formModel: {
        mobile: "",
        code: "",
        password: "",
        password2: "",
        isRead: false,
      },
      formRules: {
        mobile: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入手机号"));
              }
              if (!this.api.validate.isPhoneNumber(value)) {
                return callback(new Error("手机号格式错误"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入验证码"));
              }
              if (value.length != 6) {
                return callback(new Error("验证码必须为6位"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入密码"));
              }              
              const errMsg = this.api.validate.password(value);
              if (errMsg) {
                return callback(new Error(errMsg));
              }
              if(value === this.formModel.mobile){
                return callback(new Error("密码不可与手机号相同"))
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        password2: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入密码"));
              }
              if (value != this.formModel.password) {
                return callback(new Error("两次输入密码不一致"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        isRead: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请仔细认真阅读协议内容并勾选同意"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
      },
    };
  },
  methods: {
    validatePhone() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField("mobile", async (errMsg) => {
          if (!errMsg) {
            resolve({ bizType: 5, mobile: this.formModel.mobile });
          } else {
            reject();
          }
        });
      });
    },
    submitClick() {
      this.api.validate.submitForm(this.$refs["form"]).then(async () => {
        const { code, mobile, password, isRead } = this.formModel;
        const res = await this.api.service.uac_oapi_sso_registerLogin(
          {
            code,
            mobile,
            password: Base64.encode(password),
            readRuleRow: isRead,
          },
          {}
        );
        await this.api.toast(res);
        const data = res.data;
        this.api.storage.setCookie("systemName", "UCS", { path: "/" });
        this.api.storage.setCookie("UCS-AUTHEN-TOKEN", data.token, {
          path: "/",
        });
        this.$router.push("/");
      });
    },
  },
};
</script>

